<template>
  <div class="privacy">
    <p class="privacy-top">《面具约会隐私政策》</p><br/>
    <div class="bold right">
      <span>更新日期：2023年3月15日</span>
      <br/>
      <span>生效日期：2023年3月15日</span>
    </div>
    我们于前述时间更新了《面具约会用户隐私政策》的相关内容。本次更新的内容主要包括：依照最新法律要求更加详细地介绍了我们对用户个人信息的保护规则、完善了用户注册所需信息的描述、重点标注属于个人敏感信息的内容等。
    <br/>
    <br/>
    <span class="bold-del">在使用面具约会各项产品或服务前，请您务必仔细阅读并透彻理解本政策，特别是以粗体/粗体下划线标识的条款，您应重点阅读，在确认充分理解并同意后使用相关产品或服务。您若拒绝本政策，将无法体验完整APP功能。为保障软件及服务的安全运行，面具约会在相应功能点触发时会收集相关设备信息及权限。</span>
    <br/>
    <br/>
    如对本政策内容有任何疑问、意见或建议，您可通过面具约会提供的各种联系方式与我们联系。请您在使用继续使用面具约会产品或服务前仔细阅读和充分理解全文，并在同意全部内容后使用或继续使用。
    <br/>
    <br/>
    导言
    <br/>
    <br/>
    面具约会（以下也称“我们”，运营者：广州龙骑士网络有限公司）深知个人信息对您的重要性，因此我们非常重视保护您的隐私和个人信息，我们亦将本《面具约会用户隐私政策》（以下也称“本政策”）中的内容以高度审慎的义务对待和处理。本政策与您所使用的我们的产品或服务息息相关，您在下载、安装、启动、浏览、注册、登录、使用我们的产品或服务时，我们将按照本政策的约定处理和保护您的个人信息，因此我们希望您能够仔细阅读、充分理解本政策的全文，并在需要时，按照本政策的指引，作出您认为适当的选择。本政策之中涉及的相关技术词汇，我们尽量以简明扼要的表述向您解释，以便于您理解。 需要特别说明的是，<span class="bold-del">本政策不适用于其他第三方向您提供的服务，也不适用于已另行独立设置隐私权政策的产品或服务。</span>
    <br/>
    <br/>
    <span class="big-title">本隐私权政策将帮助您了解以下内容：</span>
    <br/>
    一、我们如何收集和使用您的个人信息<br/>
    二、我们如何使用 Cookie 和同类技术<br/>
    三、我们如何共享、转让、公开披露您的个人信息<br/>
    四、我们如何保护您的个人信息<br/>
    五、您如何管理您的个人信息<br/>
    六、我们如何处理未成年人的个人信息<br/>
    七、我们如何存储您的个人信息<br/>
    八、本隐私权政策如何更新<br/>
    九、如何联系我们
    <br/>
    <br/>
    <span class="big-title">一、我们如何收集和使用您的个人信息</span>
    <br/>
    <span class="bold">个人信息</span>是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，包括姓名、出生日期、身份证件号码、个人生物识别信息、住址、通信联系方式、通信记录和内容、账号密码、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息等。
    <br/>
    <span class="bold">个人敏感信息</span>是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，包括身份证件号码、个人生物识别信息、银行账号、通信记录和内容、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息、14周岁以下（含）儿童的个人信息等（我们将在本隐私权政策中<span class="bold">对具体个人敏感信息以粗体进行显著标识</span>）。
    <br/>
    <span class="bold-del">个人信息以及个人敏感信息包含的内容均出自于GB/T35273《个人信息安全规范》。</span>
    <br/>
    <span class="bold-del">您理解并同意：</span>
    <br/>
    <span class="bold-del">1、我们致力于打造多样的产品和服务以满足您的需求。因我们向您提供的产品和服务种类众多，且不同用户选择使用的具体产品/服务范围存在差异，相应的，收集使用的个人信息类型、范围等会有所区别，请以具体的产品/服务功能为准；</span>
    <br/>
    <span class="bold-del">2、我们可能将通过某些功能所收集的信息用于我们的其他服务。例如，我们可能将你在使用我们某一功能或服务时我们收集的信息，在另一功能或服务中用于向你提供特定内容，包括基于用户注册填写的性别推荐异性展示、基于特征标签进行间接人群画像并提供更加精准和个性化的服务和内容等。如我们使用您的个人信息，超出了与收集时所声称的目的及具有直接或合理关联的范围，我们将在使用您的个人信息前，再次向您告知并征得您的明示同意。</span>
    <br/>
    <span class="bold-del">3、为给您带来更好的产品和服务体验，我们在持续努力改进我们的技术，随之我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本政策、弹窗、页面提示等方式另行向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您明示同意后收集、使用。在此过程中，如果您有任何疑问、意见或建议的，您可通过我们提供的各种联系方式与我们联系，我们会尽快为您作出解答。</span>
    <br/>
    我们所提供产品或服务的功能分别收集的个人信息类型，以及收集、使用个人信息的规则（例如收集和使用个人信息的目的、方式）如下：
    <br/>
    1、当您注册面具约会服务时，我们会收集您的<span class="bold">头像、昵称、性别、年龄、手机号码、城市经纬度</span>用于创建面具约会账户和使用社交功能。我们将在您授权同意的范围内使用您的相关信息。<span class="bold-del">在注册与使用服务过程中，如果您提供以下信息补全个人资料：职业、身高、体重、简介、微信号，将有助于我们给您提供更好的服务和体验。但如果您不提供这些信息，将会仅使您无法使用或参与与此相关的个性化服务，不会影响使用面具约会产品或服务的基本功能。</span>您提供的上述信息将在您使用本服务期间持续授权我们使用。在您主动注销账号时，我们将根据适用法律法规的要求尽快使其匿名或删除您的个人信息。
    <br/>
    2、当您注册并开始使用面具约会各项服务时，或在面具约会切换至后台运行时，为保障您正常使用我们的服务，维护我们服务的正常运行，改进及优化我们的服务体验以及保障您的帐号安全，我们会收集关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：<span class="bold">设备信息：</span>我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（包括设备型号、操作系统版本、设置参数、设备配置、设备标识（IMEI/Android ID/OAID/IDFA/IDFV/UDID/MEID/ICCID/SIM卡IMSI信息等）、设备序列号、MAC地址、设备网络信息(运营商信息/WIFI信息/SIM卡状态/WiFi状态/WiFi列表/SSID/BSSID)、设备环境、软件列表、运行中进程信息、SDCard信息、传感器列表等软硬件特征信息）、设备所在位置相关信息（包括您授权的GPS位置以及WLAN接入点、蓝牙和基站等传感器信息）。
    <span class="bold">服务日志信息：</span>当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关服务日志保存。包括您的浏览、点击查看、发布信息，以及IP地址、浏览器的类型、运营商信息、使用的语言、访问日期和时间。<span class="bold-del">请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。</span>如果我们将这类信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。
    <span class="bold-del">为向您提供更便捷、更符合您个性化需求的信息展示及推送服务，我们会根据您的设备信息和服务日志信息，提取您的偏好特征，并基于特征标签产出间接人群画像，用于展示、推送信息,如果您不想使用我们给您提供的个性化展示及相关推荐服务，您可以在面具约会App“我的-设置”中关闭个性化推荐服务。您也可根据本隐私政策第五章“（五）约束信息系统自动决策”的介绍了解相关个性化推荐服务。</span>
    <br/>
    3、当您使用动态、聊天及其他信息发布功能公开发布信息，包括发布图文/视频/语言，您需要相应的开启<span class="bold">相册、相机、麦克风权限</span>来拍摄照片或视频或语音交互。<span class="bold-del">请注意，您公开发布的信息中可能会涉及您或他人的个人信息甚至个人敏感信息，如您在发布动态时选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。</span>您上传的动态照片、短视频、点赞等信息会存储在我们的服务器中，因为存储是实现这一功能所必需的。我们会以加密的方式存储，您也可以随时删除这些信息。除非经您自主选择或遵从相关法律法规要求，我们不会对外提供上述动态信息，或者将其用于该功能以外的其他用途。此外，您也可以随时在手机系统设置中关闭相册、相机、麦克风权限
    <br/>
    4、当您浏览查看附近的人、附近动态时，我们会根据您注册时的城市经纬度，默认展示对应城市的用户和动态；同时为了更精准的展示您和他人的距离，会向您申请获取位置权限信息；在获得您的自主选择同意开启<span class="bold">位置权限</span>后，记录您的<span class="bold">地理位置信息</span>。该信息属于敏感信息，拒绝提供该信息会使得无法查看与他人的精确距离，但不影响您正常使用面具约会的其他功能。此外，您也可以随时在手机系统设置中关闭位置权限
    <br/>
    5、 当您使用真人认证功能时，您需要开启相册权限上传需要认证的照片、开启<span class="bold">相机权限</span>来完成视频真人认证；<span class="bold-del">请注意，您在真人认证过程中，真实的认证视频属于个人敏感信息，将不会公开展示，仅供客服审核使用；认证的照片，在完成认证后，将公开展示在手机相册中；</span>如用户不完成真人认证，也不影响正常使用面具约会的其他功能
    <br/>
    6、当您使用聊天功能时，如果您在使用中通过文字、图片、语音、视频与用户进行互动，在您授权同意后，我们会访问您的<span class="bold">麦克风、相册、相机</span>信息，为您提供语音聊天、图片/视频聊天功能，我们可能会收集并保存您发送的上述信息内容用于过滤色情、暴力、政治、辱骂、恶意广告等不当内容，以此净化内容生态，维护健康的上网环境。
    <br/>
    7、 当您使用职业认证功能时，您需要开启相册权限上传照片资料用于认证；<span class="bold-del">请注意，您在职业过程中，上传的职业认证信息属于个人敏感信息，将不会公开展示，仅供客服审核使用；</span>
    <br/>
    8、当您使用邀请有礼功能时，代表您已经同意<router-link :to="{name: 'ShareUserAgreement'}" class="privacy-word">《分享协议》</router-link>和<router-link :to="{name: 'ShareUserRule'}" class="privacy-word">《分享推广规范》</router-link>、了解分享和返利的具体规则；仅在合法合规的范围内分享APP。当您进行收益提现时，我们会收集您提供的<span class="bold">支付宝账户信息</span>以及<span class="bold">身份认证信息（真实姓名、身份证号、生物识别特征）。</span><span class="bold-del">如您不同意<router-link :to="{name: 'ShareUserAgreement'}" class="privacy-word">《分享协议》</router-link>、<router-link :to="{name: 'ShareUserRule'}" class="privacy-word">《分享推广规范》</router-link>、《分享规则》，请勿进行分享，此外不会影响使用面具约会产品或服务的基本功能</span>
    <br/>
    9、当您在购买会员和其它充值服务时，需要提供下单与完成交易所需要的信息，例如：交易订单号、支付账户名称、支付渠道、支付时间及支付金额等，此外，我们也可能会再收集一些其他与订单相关的信息，我们收集这些信息是为了顺利完成交易、保障用户的交易安全、查询订单信息、提供客服和售后服务等。
    <br/>
    10、当您联系客服或使用其他用户响应功能时（包括：提出我们的产品与/或服务的售后申请、行使您的相关个人信息控制权、其他客户投诉和需求），为了用户的账号与系统安全，我们可能需要用户先行提供账号信息，并与用户之前的个人信息相匹配以验证您的用户身份。在用户使用客服或其他用户响应功能时，我们可能还会需要收集您的如下个人敏感信息：联系方式（用户与我们联系时使用的电话号码/电子邮箱或用户向我们主动提供的其他联系方式）、用户与我们的沟通信息（包括文字/图片/音视频/通话记录形式）、与用户需求相关联的其他必要信息。我们收集这些信息是为了调查事实与帮助用户解决问题，如用户拒绝提供可能导致用户无法使用我们的客服等用户响应机制。
    <br/>
    11、为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或面具约会相关协议规则的情况，<span class="bold-del">我们可能使用或整合您的用户信息、交易信息、设备信息、有关网络日志以及我们关联公司、合作伙伴取得您授权或依据法律共享给我们的信息，</span>来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
    <br/>
    12、您理解并同意，我们提供的服务可能需要您在您的设备中开启您的<span class="bold">位置信息 （地理位置）、摄像头（相机）、相册（图片库）、麦克风（语音）、通讯录的访问权限</span>，以实现这些权限所涉及信息的收集和使用。<span class="bold-del">我们会在您使用相关服务时弹窗提示您是否要开启相应权限。</span>您可在您的设备设置或客户端“更多-设置-隐私—系统权限管理”中逐项查看上述权限的状态，并可自行决定这些权限随时的开启或关闭。 <span class="bold-del">请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。</span>
    <br/>
    13、<span class="bold-del">您知悉并同意，对于您在使用产品及/或服务的过程中提供的您的联系方式（例如：联系电话），我们在运营中可能会向其中的一种或多种发送多类通知，用于用户消息告知、身份验证、安全验证、用户使用体验调研等用途；此外，我们也可能会以短信、电话的方式，为您提供您可能感兴趣的服务、功能或活动等商业性信息的用途，但请您放心，如您不愿接受这些信息，您可以通过手机短信中提供的退订方式进行退订。</span>
    <br/>
    14、<span class="bold-del">若你提供的信息中含有其他用户的个人信息，在向面具约会提供这些个人信息之前，您需确保您已经取得合法的授权。</span>
    <br/>
    15、<span class="bold-del">若我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，或者我们主动从第三方处获取您的个人信息，均会事先获得您的同意</span>
    <br/>
    <br/>
    <span class="big-title">征得授权同意的例外</span>
    <br/>
    您充分理解并同意，我们在以下情况下收集、使用您的个人信息无需您的授权同意，且我们可能不会同意您提出的更正/修改、删除、注销、撤回同意、索取信息的请求：
    <br/>
    1、与我们履行法律法规规定的义务相关的；<br/>
    2、与国家安全、国防安全有关的；<br/>
    3、与公共安全、公共卫生、重大公共利益有关的；<br/>
    4、与犯罪侦查、起诉、审判和判决执行等有关的；<br/>
    5、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；<br/>
    6、所收集的个人信息是您自行向社会公众公开的；<br/>
    7、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；<br/>
    8、根据您的要求签订合同所必需的；<br/>
    9、用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；   <br/>
    10、为合法的新闻报道所必需的；   <br/>
    11、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；<br/>
    12、法律法规规定的其他情形。<br/>
    请知悉，根据适用的法律，若我们<span class="bold-del">对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，</span>或我们可能会对收集的信息进行去标识化地研究、统计分析和预测，用于改善面具约会的内容和布局，为商业决策提供产品或服务支撑，以及改进我们的产品和服务，<span class="bold-del">则此类处理后数据的使用无需另行向您通知并征得您的同意。</span>
    <br/>
    如我们停止运营面具约会产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。
    <br/>
    <br/>
    <span class="big-title">二、我们如何使用 Cookie 和同类技术</span>
    <br/>
    <span class="big-title">（一）Cookie</span>
    <br/>
    为确保网站正常运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的移动设备上存储名为 Cookie 的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于 Cookie，网站能够存储您的偏好等数据。您可在浏览器中进行相应的数据清除操作。如您进行清除，您可能无法使用由我们提供的、依赖于Cookie的服务或相应功能。
    <br/>
    <span class="big-title">（二）Cookie同类技术</span>
    <br/>
    除 Cookie 外，我们还会在网站上使用其他同类技术。例如，我们向您发送的站内通知可能含有链接至我们网站内容的地址链接，如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好以便于我们主动改善客户服务体验。
    <br/>
    <br/>
    <span class="big-title">三、我们如何共享、转让、公开披露您的个人信息</span>
    <br/>
    我们已知晓对外共享、转让、公开披露个人信息所承担的相应法律责任，我们仅会在本隐私政策约定的范围内共享、转让、公开披露您的个人信息：
    <br/>
    <span class="big-title">（一）共享</span>
    <br/>
    我们会重视对您的个人信息的保护，您的个人信息是我们为您提供产品或服务的重要依据和组成部分，对于您的个人信息，我们仅在本《面具约会用户隐私政策》所述目的和范围内或根据法律法规的要求收集和使用，并严格保密，我们不会与面具约会以外的第三方公司、组织和个人共享您的个人信息，除非存在以下一种或多种情形：
    <br/>
    ▪ 您自行提出要求的；
    <br/>
    ▪ 事先已征得您的明确授权同意；
    <br/>
    ▪ 与第三方业务合作伙伴的必要共享；
    <br/>
    您理解并同意，为了必要/合理的业务的顺利开展、满足您的要求、履行我们在相关用户协议或本个人信息保护政策中的义务和行使我们的权利或遵守法律规定等目的，我们可能需要向部分业务合作伙伴（第三方服务供应商、承包商、代理、数据分析合作伙伴、应用开发者等，例如，为我们提供位置服务的地图服务供应商）共享您的个人信息，我们承诺要求第三方按照我们的说明、个人信息保护政策以及其他相关的保密和安全措施来为我们处理上述信息，并用于以下用途：
    <br/>
    我们的业务合作伙伴包括：
    <br/>
    ●为我们的产品与/或服务提供功能支持的服务提供商：包括：提供支付服务的支付机构、提供一键登录服务的运营商和其他服务提供商，我们共享信息的目的是可以实现我们产品与/或服务的功能，让您可以正常使用；
    <br/>
    ●提供技术、咨询服务的供应商。我们可能会将您的个人信息共享给支持我们提供服务的第三方。这些机构包括为我们提供基础设施技术服务、数据处理和客户服务的机构。但我们要求这些服务提供商只能出于为我们提供服务的目的使用您的信息，而不得出于其自身利益使用您的信息。
    <br/>
    ●分析信息类的合作伙伴：为维护/改进我们的产品/服务、为您提供更好的内容，我们可能会与提供该服务的指定合作伙伴共享您的相关信息（包括：终端型号、设备信息（如android-id）、您所在的城市及其他相关信息）。对于分析数据的伙伴，为了更好的分析面具约会用户的使用情况，我们可能向其提供面具约会用户的数量、地区分布、活跃情况等数据，但我们仅会向这些合作伙伴提供不能识别个人身份的统计信息。我们承诺：未经您的同意，我们不会与其共享可以识别您身份的个人信息；
    <br/>
    ●为了使您能够使用下述服务及功能，我们的应用中会接入第三方SDK<a href="https://mianjuyuehui.net/webs/sdk-info">《第三方SDK目录》</a>
    <br/>
    <br/>
    前述服务商收集和处理信息等行为遵守其自身的隐私条款，而不适用于本政策。但我们也会努力审查该第三方的业务准入资质并努力要求该等服务商的合法合规性与安全性。为了最大程度保障您的信息安全，我们强烈建议您在使用任何第三方SDK类服务前仔细阅读其隐私条款。为保障您的合法权益，如您发现这等SDK或其他类似的应用程序存在风险时，建议您立即终止相关操作并及时与我们取得联系；
    <br/>
    上述共享的是去标识化的信息，且接受该信息共享的第三方无法重新识别信息主体身份；<br/>
    某些情况下，只有共享您的个人信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务，或处理您与他人的纠纷或争议：<br/>
    ● 依据您与我们签署的相关协议（例如：在线协议、平台规则等）或法律文件而共享的；<br/>
    ● 符合您与其他第三人之间的有关约定的；<br/>
    ● 基于合理商业习惯而共享的，例如：与第三方共享联合营销活动中的中奖/获胜者等信息，以便其能及时向您发放奖品/礼品等；我们接受尽调时等；<br/>
    ● 基于学术研究而使用，例如：以学术研究为目的而与学术机构共享我们使用您的相关信息集合形成的间接用户画像、去标识化或匿名化处理后的分析/统计类信息（未经您的同意，我们不会与其共享可以识别您身份的个人信息）；学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行匿名化处理的；<br/>
    ● 有权机关的要求、诉讼争议需要、法律法规等规定的其他需要共享的情形。<br/>
    <br/>
    <br/>
    您可以基于面具约会平台与第三人（包括不特定对象）共享您的个人信息或其他信息，但因您的共享行为而导致的信息泄露、被使用等情况，与我们无关，您需要自行承担相应的法律责任。<br/>
    我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享必要的个人信息。在我们与业务合作伙伴共享信息时，我们会以多种方式保护您的个人信息，包括但不限于：（1）如具备上述合理事由且需要您授权同意的，我们会在共享前向您告知共享的信息的目的、类型（如涉及您的个人敏感信息的，我们还会向您告知涉及的敏感信息的内容）并在取得您授权同意后再共享；（2）我们的安全团队将对信息数据的输出形式、流转、使用等做安全评估与处理等。请您知悉，即使已经取得您的授权同意，我们也仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并尽量对共享内容中的个人信息进行匿名化处理。您理解并知悉，匿名化处理后的信息无法指向与识别您，已不属于法律意义上的个人信息，其处理、使用、共享、转让无需征得您的授权同意。
    <br/>
    <br/>
    <span class="big-title">（一）转让</span>
    <br/>
    转让是指将个人信息控制权向其他公司、组织或个人转移的过程。原则上我们不会将您的个人信息转让，但以下情况除外：<br/>
    ● 您自行提出要求的；<br/>
    ● 事先已征得您的明确授权同意；<br/>
    如我们进行兼并、收购、重组、分立、破产、资产转让或类似的交易，而您的个人信息有可能作为此类交易的一部分而被转移，我们会要求新持有人继续遵守和履行该《面具约会用户隐私政策》的全部内容（包括使用目的、使用规则、安全保护措施等），否则我们将要求其重新获取您的明示授权同意；<br/>
    法律法规等规定的其他情形。<br/>
    如具备上述事由确需转让的，我们会在转让前向您告知转让的信息的目的、类型（如涉及您的个人敏感信息的，我们还会向您告知涉及的敏感信息的内容），并在征得您的明示同意后再转让，但法律法规另有规定的或本政策另有约定的除外。<br/>
    <span class="big-title">（一）公开披露</span>
    <br/>
    我们不会将用户的个人信息转让给任何公司、组织和个人，但以下情况除外：<br/>
    ● 在获取明示同意的情况下转让：获得用户的明示同意后，我们会向其他方转让用户的个人信息；<br/>
    ● 在我方平台服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有用户个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向用户征求授权同意。<br/>
    ● 与国家安全、国防安全有关的；<br/>
    ● 与公共安全、公共卫生、重大公共利益有关的；<br/>
    ● 与犯罪侦查、起诉、审判和判决执行等有关的；<br/>
    ● 出于维护个人的生命、财产等重大合法权益但又很难得到本人同意的；<br/>
    ● 用户自行向社会公众公开的个人信息<br/>
    ● 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道<br/>
    <br/>
    <span class="big-title">四、我们如何保护您的个人信息</span>
    <br/>
    “面具约会”一直都极为重视保护用户的个人信息安全，为此我们采用了符合行业标准的安全技术措施及配套的组织架构和管理体系等多层面保护措施以最大程度降低用户的信息被泄露、毁损、误用、非授权访问、非授权披露和更改的风险。包括：<br/>
    ●数据安全传输方面，采用传输层安全协议等密码技术，通过Https等方式防止传输链路被嗅探、窃听截取风险，建立安全的隐私数据采集环境，保证数据采集的私密性和完整性；<br/>
    ●数据安全存储方面，对数据进行分类分级处置并对用户个人敏感信息采取独立、加密存储等额外的安全保护措施；<br/>
    ●数据访问和使用的安全控制方面，实施严格的数据权限控制机制，采取多重身份认证技术，并对能够处理用户的信息的行为进行监控，避免数据被违规访问和未授权使用；<br/>
    ●建立完整的审计机制，对数据生命周期的全流程进行监控与审计，防止用户的个人信息遭遇未经授权的访问、公开披露、使用、修改、人为或意外的损坏或丢失；<br/>
    ●建立相关的内控管理流程，对可能接触到用户的信息的工作人员采取最小化权限原则；<br/>
    ●建立数据分类分级制度、业务数据安全使用规范、数据合作规范等管理体系，保障用户的信息在收集、传输、使用、存储、转移、销毁等环节的处置满足法律法规相关规范和安全要求；<br/>
    ●互联网并非绝对安全的环境，<span class="bold-del">我们强烈建议您不要使用非面具约会推荐的通信方式发送个人信息。</span>您可以通过我们的服务建立联系和相互分享。当您通过我们的服务创建交流或分享时，您可以自主选择沟通、分享的对象，作为能够看到您的交易内容、联络方式、交流信息或分享内容等相关信息的第三方。
    <br/>
    如您发现自己的个人信息尤其是您的账户发生泄露，请您立即联络面具约会客服，以便我们根据您的申请采取相应措施。
    <br/>
    请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您在发表动态或者在聊天等公众场合选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。
    <br/>
    同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
    <br/>
    <br/>
    <span class="big-title">五、您如何管理您的个人信息</span>
    <br/>
    您可以通过以下方式访问及管理您的个人信息：
    <br/>
    <span class="big-title">（一）查询、更正和补充您的个人信息，并获取您的个人信息副本</span>
    您有权访问您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的个人信息：<br/>
    账户信息——如果您希望注销您的账户等，您可以通过登录账号通过“我的”-“设置”-“注销账号”执行此类操作。<br/>
    个人资料—如果您希望访问或编辑您个人资料中的昵称、头像、年龄、身高、体重、职业、简介、微信号其他资料等，您可以通过登录账户通过“我的”-“编辑个人资料”执行此类操作。<br/>
    动态信息——您可以登录账号通过“我的动态”访问或清除您的动态发表历史记录。<br/>
    如果您无法通过上述路径访问该等个人信息，您可以随时通过在线客服与我们取得联系。我们将在15天内回复您的访问请求。<br/>
    对于您在使用我们的产品或服务过程中产生的其他个人信息，我们将根据本条“（六）响应您的上述请求”中的相关安排向您提供。<br/>
    <span class="big-title">（二）删除您的个人信息</span>
    <br/>
    您可以通过本条“（一）查询、更正或补充您的个人信息，并获得您的个人信息副本”中列明的方式删除您的部分个人信息。<br/>
    在以下情形中，您可以向我们提出删除个人信息的请求：<br/>
    1、如果我们处理个人信息的行为违反法律法规；<br/>
    2、如果我们收集、使用您的个人信息，却未征得您的明确同意；<br/>
    3、如果我们处理个人信息的行为严重违反了与您的约定；<br/>
    4、如果您不再使用我们的产品或服务，或您主动注销了账号；<br/>
    5、如果我们永久不再为您提供产品或服务。<br/>
    若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，要求其及时删除，除非法律法规另有规定，或这些主体获得您的独立授权。<br/>
    <span class="bold-del">当您或我们协助您从我们的服务中删除信息后，依据相关法律法规的规定，我们可能不会立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并将其与任何进一步处理隔离，直到备份可以清除或实现匿名。</span>
    <br/>
    <span class="big-title">（三）改变您授权同意的范围</span>
    <br/>
    每个业务功能需要一些基本的个人信息才能得以完成（见本隐私权政策“第一条”）。除此之外，对于额外个人信息的收集和使用，您可以在更多-设置或手机系统设置中修改您的授权。
    <br/>
    <span class="big-title">（四）个人信息主体注销账户</span>
    <br/>
    您可以自行在“帐号注销”页面提交账户注销申请。
    <br/>
    在您主动注销账户之后，我们将停止为您提供产品或服务，根据适用法律的要求删除您的个人信息，或使其匿名化处理。
    <br/>
    <span class="big-title">（五）约束信息系统自动决策</span>
    <br/>
    在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害面具约会商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。
    <br/>
    <span class="bold">当您使用面具约会浏览动态、用户列表等推荐内容时，为向您提供更便捷、更符合您个性化需求的信息展示及推送服务，我们会根据依法收集的设备信息、服务日志信息，结合其他取得您授权的位置信息、自主填写或筛选的性别、年龄等帐号信息，通过个性化推荐模型向您推荐您可能感兴趣的内容。我们会根据您使用产品过程中的内容浏览等相关行为，对推荐模型进行实时反馈，不断优化推荐结果，更好地向您提供更契合您需求的服务。请您知悉，我们对上述信息进行了去标识化处理，我们使用的个性化推荐算法无法也不会识别特定自然人的真实身份，仅是基于用户行为和特征提供相关性更高与更丰富的信息。</span>
    <br/>
    同时，我们通过以下措施帮助您实现对推荐内容的控制及自主决策：
    <br/>
    1、如果您不希望被推荐个性化内容，您可在面具约会APP“更多-设置-隐私-个性化推荐设置”中关闭个性化内容推荐服务。当您选择关闭个性化内容推荐后，我们将无法为您提供个性化内容服务，我们会基于内容热度等非个性化因素向您展示、推荐内容。
    <br/>
    <br/>
    <span class="big-title">（六）响应您的上述请求</span>
    <br/>
    为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。<br/>
    我们将在15天内做出答复。如您不满意，还可以通过APP在线客服发起投诉。<br/>
    对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
    <br/>
    在以下情形中，按照法律法规要求，我们将无法响应您的请求：<br/>
    1、与国家安全、国防安全有关的；<br/>
    2、与公共安全、公共卫生、重大公共利益有关的；<br/>
    3、与犯罪侦查、起诉、审判和执行判决等有关的；<br/>
    4、有充分证据表明个人信息主体存在主观恶意或滥用权利的；<br/>
    5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；<br/>
    6、涉及商业秘密的。<br/>
    <br/>
    <br/>
    <span class="big-title">六、我们如何处理未成年人的个人信息</span>
    <br/>
    任何18岁以下的未成年人均不得创建自己的用户账户及/或使用我们的服务。
    <br/>
    <br/>
    <span class="big-title">七、我们如何存储您的个人信息</span>
    <br/>
    我们在中华人民共和国境内运营中收集和产生的您的个人信息，存储在中国境内。
    <br/>
    <span class="bold-del">一般而言，我们自您使用服务时获取到您个人信息之日起至您注销账户后6个月内会存储并保留您的个人信息。</span>
    <br/>
    <span class="bold-del">在您的个人信息超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。</span>
    <br/>
    当我们的产品或服务发生停止运营的情形时，我们将以推送通知、公告等形式通知您，并在合理的期限内删除您的个人信息或进行匿名化处理。
    <br/>
    <br/>
    <span class="big-title">八、本隐私权政策如何更新</span>
    <br/>
    我们的隐私权政策可能变更。<br/>
    未经您明确同意，我们不会限制您按照本隐私权政策所应享有的权利。对于重大变更，我们会提供显著的通知<br/>
    本政策所指的重大变更包括但不限于：<br/>
    1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；<br/>
    2、我们在控制权等方面发生重大变化。如并购重组等引起的所有者变更等；<br/>
    3、个人信息共享、转让或公开披露的主要对象发生变化；<br/>
    4、您参与个人信息处理方面的权利及其行使方式发生重大变化；<br/>
    5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；<br/>
    6、个人信息安全影响评估报告表明存在高风险时。<br/>
    我们还会将本隐私权政策的旧版本存档，供您查阅。<br/>
    <br/>
    <span class="big-title">九、如何联系我们</span>
    <br/>
    您可以通过以下方式与我们联系，我们将在15天内回复您的请求： <br/>
    1、如对本政策内容有任何疑问、意见或建议，您可在APP内通过在线客服与我们联系； <br/>
    2、如发现个人信息可能被泄露，您可以发邮件至lqs888lqs@163.com投诉举报； <br/>
    3、如果您对在线客服处理不满意，也可发邮件至相关负责人邮箱，申请进一步处理您的请求：lqs888lqs@163.com <br/>
    <br/>
    如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益， <span class="bold-del">您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。</span>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  components: {  }
}
</script>

<style scoped>
.privacy-word {
  font-size: 14px;
  color: blue;
  text-decoration: underline;
}
.privacy {
  text-align: left;
  padding: 15px 15px;
  font-size: 14px;
  line-height: 20px;
}
.privacy-top {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
.table {
  width: 100%;
}
table {
  table-layout:fixed;
  word-break:break-all;
}
.bold-del {
  font-weight: bold;
  text-decoration: underline;
}
.bold {
  font-weight: bold;
}
.big-title {
  font-weight: bold;
  font-size: 18px;
}
.right {
  text-align: right;
}
</style>
